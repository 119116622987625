import styled, { css } from "styled-components"

const StyledMilestonesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 76.8rem;
  margin: 0 auto;

  ${({ theme }) => theme.mq.medium} {
    flex-wrap: nowrap;
  }
`

const StyledMilestonesStages = styled.div`
  min-width: 25.6rem;
  width: 100%;

  ${({ theme }) => theme.mq.medium} {
    padding-right: 3.2rem;
    width: 70%;
  }
`

const StyledMilestonesContent = styled.div`
  min-width: 28.6rem;
  width: 100%;
`

const stageDescriptionStyles = css`
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  margin-bottom: 0.8rem;
`

const StyledMilestoneListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
`
const textStyles = css`
  hyphens: auto;
  text-align: justify;
`
export {
  StyledMilestonesWrapper,
  StyledMilestonesStages,
  StyledMilestonesContent,
  stageDescriptionStyles,
  StyledMilestoneListWrapper,
  textStyles,
}
