import styled, { css } from "styled-components"

const closeButtonStyles = css`
  position: fixed;
  top: 4.8rem;
  left: 1rem;
  border: none;
  background: none;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.color.white};

  &:hover {
    background-color: ${({ theme }) => theme.color.main03};
  }

  ${({ theme }) => theme.mq.large} {
    left: 6.4rem;
  }

  ${({ theme }) => theme.mq.extraLarge} {
    left: calc(50% - 48rem);
  }
`

const linkButtonStyles = css`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.secondary01};
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
  &:active {
    color: ${({ theme }) => theme.color.secondary01};
  }
`

const StyledPostNavigation = styled.div`
  height: 4.8rem;
  display: flex;
  align-items: center;
  position: relative;
`

const StyledBackButton = styled.div`
  position: absolute;
  left: 1.6rem;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  display: flex;

  & svg {
    height: 1.4rem;
    fill: ${({ theme }) => theme.color.secondary02};
  }

  &:hover,
  &:active {
    & svg {
      fill: ${({ theme }) => theme.color.secondary01};
    }
  }
`

const StyledTitle = styled.span`
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  color: ${({ theme }) => theme.color.secondary01};
`

export {
  closeButtonStyles,
  linkButtonStyles,
  StyledPostNavigation,
  StyledTitle,
  StyledBackButton,
}
