import React from "react"
import { graphql } from "gatsby"
import { isEmptyRedirect } from "utils/redirect"
import PropTypes from "prop-types"

import ProjectTimelinePost from "components/content/Portfolio/ProjectTimelinePosts"
import ClientOnly from "components/atoms/ClientOnly"

const PortfolioTimelinePostPage = ({ data, pageContext: { language } }) => {
  const {
    api: { project },
  } = data
  const { timelinePost } = project || {}
  isEmptyRedirect(timelinePost)
  return timelinePost ? (
    <ClientOnly>
      <ProjectTimelinePost componentData={timelinePost} language={language} />
    </ClientOnly>
  ) : (
    <></>
  )
}

PortfolioTimelinePostPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      project: PropTypes.shape({
        timelinePost: PropTypes.shape(),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}
export const query = graphql`
  query PortfolioTimelinePostPageData(
    $apiLocale: API_Locale
    $projectSlug: String!
    $postSlug: String!
  ) {
    api {
      project(slug: $projectSlug, language: $apiLocale) {
        timelinePost(slug: $postSlug, language: $apiLocale) {
          category
          isPublished
          project {
            id
            name
          }
          title
          updatePost {
            body
            mediaType
            video {
              videoThumbnail
              videoUrl
            }
            images {
              caption
              mediaType
              file
              title
            }
          }
          publicationDate
          publicationDateDisplaySetting
          videoReportPost {
            body
            transcription
            videoUrl
            videoThumbnail
          }
          project {
            slug
          }
          quarterlyReportPost {
            nextSteps
            projectUpdate
            reportingEndDate
            reportingQuarter
            reportingStartDate
            seePhotosUpdate
            seeProgressDisplay
            signOffNote
            signOffNoteAuthor
            signOffNoteAuthorSignature
            signOffNoteAuthorTitle
            videoUpdateDisplay
            welcomeClosingNote
            welcomeClosingNoteAuthor
            welcomeClosingNoteAuthorSignature
            welcomeClosingNoteAuthorTitle
            welcomeImage
            welcomeOpenNote

            stages {
              id
              milestones {
                body
                completionDate
                completionDateDisplaySetting
                position
                title
                status
                stage {
                  status
                }
              }
              stage {
                description
                name
              }
            }
            seeVideoUpdates {
              videoUrl
              videoThumbnail
            }
            seePhotos {
              file
              id
              position
            }
          }
        }
      }
    }
  }
`

export default PortfolioTimelinePostPage
