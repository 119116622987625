import styled, { css } from "styled-components"
import { ButtonBack, ButtonNext } from "pure-react-carousel"
import { transparentize } from "polished"
import Image from "components/atoms/Image"
import ReactPlayer from "react-player/lazy"

const carouselButtonsStyles = css`
  position: absolute;
  top: calc(50% - 1.2rem);
  transform: translateY(-50%);
  border: none;
  outline: none;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.color.secondary01};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.main03};

    > svg {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`

const sectionTitleStyles = css`
  text-align: center;
  padding-bottom: 3.2rem;

  span {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    box-shadow: inset 0 -0.2em white,
      inset 0 -0.3em ${({ theme }) => theme.color.senary03};

    &:before {
      content: "\\00a0";
      display: inline-block;
      background: ${({ theme }) => theme.color.white};
    }
  }
`

const noteAuthorStyles = css`
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
`

const noteAuthorTitleStyles = css`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 2rem;
`

const noteAuthorSignatureStyles = css`
  padding: 0 2.4rem 0 1.6rem;
  font-size: ${({ theme }) => theme.font.size.xl};
  font-family: ${({ theme }) => theme.font.family.signature};
`

const textStyles = css`
  hyphens: auto;
  text-align: justify;
`

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem 0 0;
  align-items: center;

  ${({ theme }) => theme.mq.medium} {
    max-width: 100%;
    margin: 0 auto;
    padding: 3.2rem 0;
  }
`

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem 0 0;
  max-width: 36.8rem;
`

const StyledDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4rem;

  span {
    font-size: ${({ theme }) => theme.font.size.xs};
    color: ${({ theme }) => theme.color.secondary02};
  }
`

const StyledDetailsSeparator = styled.div`
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background: ${({ theme }) => theme.color.secondary03};
  margin: 0 0.8rem;
`

const StyledParagraphWrapper = styled.div`
  max-width: 36.8rem;
  width: 100%;
  padding: 4.8rem ${({ theme }) => theme.layout.mobilePadding};
`

const StyledTextContainer = styled.div`
  padding-bottom: 3.2rem;
  max-width: 56.8rem;
  margin: 0 auto;
  order: 3;
  overflow-wrap: break-word;
  white-space: break-spaces;

  > p {
    margin-bottom: 2.4rem;
    ${textStyles}

    span {
      ${textStyles}
    }
  }

  img,
  video {
    max-width: 100%;
  }

  ${({ theme }) => theme.mq.medium} {
    > p {
      margin-bottom: 4.8rem;
    }
  }
`

const StyledImage = styled(Image)`
  height: 100%;
  max-height: 27.6rem;
  width: 100%;
  object-fit: cover;
  margin: 0 0 3.2rem;
`

const StyledNote = styled.div`
  width: 100%;
  max-width: 56.8rem;
  padding: 2.4rem;
  margin: 4.8rem 0;
  background: ${({ theme }) => theme.color.secondary06};
`

const StyledNoteSignature = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
`

const StyledMediaWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.gridWidth};
  padding: 4.8rem 0;

  ${({ theme }) => theme.mq.extraLarge} {
    padding: 4.8rem 0;
  }
`

const StyledMilestoneWrapper = styled.div`
  width: 100%;
  padding: 4.8rem ${({ theme }) => theme.layout.mobilePadding};
`

const StyledDotContainer = styled.div`
  width: max-content;
  display: flex;
  margin: 2.4rem auto 0;

  & .carousel__dot {
    width: 6.4rem;
    height: 6.4rem;
    padding: 0;
    border: 0;
    margin-right: 1.6rem;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    transition: transform 300ms ease-in;

    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: ${({ theme }) => theme.color.white};
      transition: opacity 300ms linear;
      opacity: 0.6;
      top: 0;
      z-index: 1;
    }

    &.carousel__dot--selected {
      &:before {
        display: none;
      }
    }

    &:hover {
      &:before {
        opacity: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 300ms linear !important;
    }

    &:hover img {
      transform: scale(1.1);
    }

    ${({ currentSlide }) =>
      currentSlide &&
      css`
        transform: translateX(
          calc(-100% * ${currentSlide} - 1.6rem * ${currentSlide})
        );
      `};
  }

  & .carousel__back-button,
  & .carousel__next-button {
    border: 0;
    background: transparent;
    outline: none;

    & > svg {
      fill: ${({ theme }) => theme.color.secondary02};
      width: 2rem;
      height: 2rem;

      &:hover {
        fill: ${({ theme }) => theme.color.secondary01};
      }
    }
  }

  & .carousel__back-button {
    margin-right: 1.6rem;
  }
  & .carousel__next-button {
    margin-left: 1.6rem;
  }
`

const StyledThumbnailsWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;

  ${({ visibleThumbnails }) =>
    visibleThumbnails &&
    css`
      max-width: calc(
        6.4rem * ${visibleThumbnails} + 1.6rem * ${visibleThumbnails}
      );
    `}
`

const StyledSlideImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledPlayButton = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  background: ${({ theme }) => transparentize(0.52, theme.color.secondary01)};

  border-radius: 50%;
  border: 0.2rem solid ${({ theme }) => theme.color.white};
  transition: border-color 0.15s linear;
`

const StyledTriangle = styled.div`
  position: absolute;
  left: 0.6rem;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;

  border-left: 1.6rem solid white;

  transition: border-color 0.15s linear;
`

const StyledSliderButtonBack = styled(ButtonBack)`
  ${carouselButtonsStyles};
  left: 0.8rem;
`
const StyledSliderButtonNext = styled(ButtonNext)`
  ${carouselButtonsStyles};
  right: 0.8rem;
`

const StyledCarouselWrapper = styled.div`
  position: relative;
`

const thumbnailBackButtonStyles = css`
  ${({ disabled }) => disabled && "opacity: 0.2"};
`
const StyledThumbnailButtonBack = styled(ButtonBack)`
  ${thumbnailBackButtonStyles}
`
const StyledThumbnailButtonNext = styled(ButtonNext)`
  ${thumbnailBackButtonStyles}
`

const StyledReactPlayer = styled(ReactPlayer)`
  &:hover > div > div:last-child {
    border-color: ${({ theme }) => theme.color.senary03};
  }

  &:hover > div > div:last-child div {
    border-left-color: ${({ theme }) => theme.color.senary03};
  }
`

export {
  sectionTitleStyles,
  noteAuthorStyles,
  noteAuthorTitleStyles,
  noteAuthorSignatureStyles,
  StyledSection,
  StyledHeaderWrapper,
  StyledDetailsWrapper,
  StyledDetailsSeparator,
  StyledParagraphWrapper,
  StyledImage,
  StyledNote,
  StyledNoteSignature,
  StyledMediaWrapper,
  StyledMilestoneWrapper,
  StyledDotContainer,
  StyledThumbnailsWrapper,
  StyledSlideImage,
  StyledPlayButton,
  StyledTriangle,
  StyledCarouselWrapper,
  StyledSliderButtonBack,
  StyledSliderButtonNext,
  StyledThumbnailButtonBack,
  StyledThumbnailButtonNext,
  StyledTextContainer,
  StyledReactPlayer,
  textStyles,
}
