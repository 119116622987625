import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import { useIntl } from "gatsby-plugin-intl"
import DOMPurify from "dompurify"

import Q1 from "assets/images/q1.svg"
import Q2 from "assets/images/q2.svg"
import Q3 from "assets/images/q3.svg"
import Q4 from "assets/images/q4.svg"

import formatDate from "utils/formatDate"
import getProjectTimelineReportType from "utils/getProjectTimelineReportType"
import useWindowSize from "utils/useWindowSize"
import theme from "styles/theme"
import getMediaUrl from "utils/getMediaUrl"
import handleCurrentThumbnails from "utils/carouselThumbnailsHelper"
import browserWindow from "utils/browserWindow"
import getReactPlayerPlaceholder from "utils/getReactPlayerPlaceholder"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import P from "components/atoms/P"
import H from "components/atoms/H"

import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel"
import ChevronLeft from "assets/icons/chevron-left.svg"
import ChevronRight from "assets/icons/chevron-right.svg"
import Milestones from "./Milestones"

import {
  sectionTitleStyles,
  noteAuthorStyles,
  noteAuthorTitleStyles,
  noteAuthorSignatureStyles,
  StyledSection,
  StyledHeaderWrapper,
  StyledDetailsWrapper,
  StyledDetailsSeparator,
  StyledParagraphWrapper,
  StyledImage,
  StyledNote,
  StyledNoteSignature,
  StyledMediaWrapper,
  StyledMilestoneWrapper,
  StyledDotContainer,
  StyledThumbnailsWrapper,
  StyledSlideImage,
  StyledPlayButton,
  StyledTriangle,
  StyledCarouselWrapper,
  StyledSliderButtonBack,
  StyledSliderButtonNext,
  StyledThumbnailButtonBack,
  StyledThumbnailButtonNext,
  StyledTextContainer,
  StyledReactPlayer,
  textStyles,
} from "./QuarterlyReportPost.styles"

const elementQuarter = (quarterlyReport) => {
  const quarterComponents = {
    Q1: <Q1 />,
    Q2: <Q2 />,
    Q3: <Q3 />,
    Q4: <Q4 />,
  }

  return quarterComponents[quarterlyReport] || []
}

const RenderNote = ({
  note,
  noteAuthorSignature,
  noteAuthor,
  noteAuthorTitle,
}) => (
  <StyledNote>
    <P extraStyles={textStyles}>{note}</P>
    <StyledNoteSignature>
      <P extraStyles={noteAuthorSignatureStyles}>{noteAuthorSignature}</P>

      <div>
        <P extraStyles={noteAuthorStyles}>{noteAuthor}</P>
        <P extraStyles={noteAuthorTitleStyles}>{noteAuthorTitle}</P>
      </div>
    </StyledNoteSignature>
  </StyledNote>
)

const QuarterlyReportPost = ({ content }) => {
  const [currentPhotoSlide, setCurrentPhotoSlide] = useState(0)
  const [currentVideoSlide, setCurrentVideoSlide] = useState(0)
  const [playVideo, setPlayVideo] = useState(true)
  const carouselRef = useRef(null)
  const visibleThumbnails = 5

  const size = useWindowSize()
  const { formatMessage } = useIntl()

  const {
    publicationDate,
    category,
    quarterlyReportPost,
    publicationDateDisplaySetting,
  } = content

  const {
    welcomeImage,
    welcomeOpenNote,
    seePhotosUpdate,
    seePhotos,
    welcomeClosingNote,
    welcomeClosingNoteAuthorSignature,
    welcomeClosingNoteAuthor,
    welcomeClosingNoteAuthorTitle,
    signOffNote,
    signOffNoteAuthor,
    signOffNoteAuthorSignature,
    signOffNoteAuthorTitle,
    projectUpdate,
    seeVideoUpdates,
    nextSteps,
    stages,
    reportingQuarter,
    reportingEndDate,
    reportingStartDate,
  } = quarterlyReportPost

  const domPurify = DOMPurify(browserWindow)

  const handleKeyDown = (event) => {
    const { carouselStore } = carouselRef.current
    const { state } = carouselStore
    const { key } = event

    if (
      (key === "ArrowRight" || key === "d") &&
      state.currentSlide < seePhotos.length - 1
    ) {
      carouselStore.setStoreState({
        ...state,
        currentSlide: state.currentSlide + 1,
      })
      setCurrentPhotoSlide(currentPhotoSlide + 1)
    }
    if ((key === "ArrowLeft" || key === "a") && state.currentSlide >= 1) {
      carouselStore.setStoreState({
        ...state,
        currentSlide: state.currentSlide - 1,
      })
      setCurrentPhotoSlide(currentPhotoSlide - 1)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [currentPhotoSlide])

  return (
    <StyledSection>
      <StyledHeaderWrapper>
        {elementQuarter(reportingQuarter)}
        <StyledDetailsWrapper>
          <span>
            {formatDate(publicationDate, publicationDateDisplaySetting)}
          </span>
          <StyledDetailsSeparator />
          <span data-testid="reportType">
            {getProjectTimelineReportType(category)}
          </span>
        </StyledDetailsWrapper>
      </StyledHeaderWrapper>

      <StyledParagraphWrapper>
        <StyledImage src={welcomeImage} alt="" />
        <P extraStyles={textStyles}>{welcomeOpenNote}</P>
      </StyledParagraphWrapper>

      {seePhotosUpdate && (
        <StyledMediaWrapper>
          <H as="h3" extraStyles={sectionTitleStyles}>
            {formatMessage({
              id:
                "pages.portfolio.project.timeline.post.quarterlyReport.photosTitle",
            })}
          </H>
          <CarouselProvider
            ref={carouselRef}
            naturalSlideWidth={100}
            naturalSlideHeight={
              size.width >= theme.breakpoints.medium ? 42 : 50
            }
            totalSlides={seePhotos.length}
            touchEnabled={false}
          >
            <StyledCarouselWrapper>
              <Slider>
                {seePhotos.map((image, index) => (
                  <Slide index={index} key={image.file}>
                    <StyledSlideImage src={getMediaUrl(image.file)} alt="" />
                  </Slide>
                ))}
              </Slider>
              {size.width < theme.breakpoints.medium && seePhotos.length > 1 && (
                <>
                  <StyledSliderButtonBack
                    onClick={() => {
                      setCurrentPhotoSlide(currentPhotoSlide - 1)
                      trackCustomEventTrigger({
                        category: trackingCategoriesHelper.PROJECT_TIMELINE,
                        action: "Click",
                        label: `${trackingCategoriesHelper.PROJECT_TIMELINE} quarterly report see photos button(left arrow)`,
                      })
                    }}
                    disabled={currentPhotoSlide === 0}
                  >
                    <ChevronLeft />
                  </StyledSliderButtonBack>
                  <StyledSliderButtonNext
                    onClick={() => {
                      setCurrentPhotoSlide(currentPhotoSlide + 1)
                      trackCustomEventTrigger({
                        category: trackingCategoriesHelper.PROJECT_TIMELINE,
                        action: "Click",
                        label: `${trackingCategoriesHelper.PROJECT_TIMELINE} quarterly report see photos button(right arrow)`,
                      })
                    }}
                    disabled={currentPhotoSlide === seePhotos.length - 1}
                  >
                    <ChevronRight />
                  </StyledSliderButtonNext>
                </>
              )}
            </StyledCarouselWrapper>
            {size.width >= theme.breakpoints.medium && seePhotos.length > 1 && (
              <StyledDotContainer
                currentSlide={handleCurrentThumbnails(
                  seePhotos,
                  currentPhotoSlide,
                  visibleThumbnails
                )}
              >
                <StyledThumbnailButtonBack
                  onClick={() => {
                    setCurrentPhotoSlide(currentPhotoSlide - 1)
                    trackCustomEventTrigger({
                      category: trackingCategoriesHelper.PROJECT_TIMELINE,
                      action: "Click",
                      label: `${trackingCategoriesHelper.PROJECT_TIMELINE} quarterly report see photos button(left arrow)`,
                    })
                  }}
                  disabled={currentPhotoSlide === 0}
                >
                  <ChevronLeft />
                </StyledThumbnailButtonBack>
                <StyledThumbnailsWrapper visibleThumbnails={visibleThumbnails}>
                  {seePhotos.map((element, index) => (
                    <Dot
                      slide={index}
                      onClick={() => setCurrentPhotoSlide(index)}
                      key={element.id}
                    >
                      <StyledImage src={getMediaUrl(element.file)} alt="" />
                    </Dot>
                  ))}
                </StyledThumbnailsWrapper>
                <StyledThumbnailButtonNext
                  onClick={() => {
                    setCurrentPhotoSlide(currentPhotoSlide + 1)
                    trackCustomEventTrigger({
                      category: trackingCategoriesHelper.PROJECT_TIMELINE,
                      action: "Click",
                      label: `${trackingCategoriesHelper.PROJECT_TIMELINE} quarterly report see photos button(right arrow)`,
                    })
                  }}
                  disabled={currentPhotoSlide === seePhotos.length - 1}
                >
                  <ChevronRight />
                </StyledThumbnailButtonNext>
              </StyledDotContainer>
            )}
          </CarouselProvider>
        </StyledMediaWrapper>
      )}

      <RenderNote
        note={welcomeClosingNote}
        noteAuthorSignature={welcomeClosingNoteAuthorSignature}
        noteAuthor={welcomeClosingNoteAuthor}
        noteAuthorTitle={welcomeClosingNoteAuthorTitle}
      />

      {stages.length > 0 && (
        <StyledMilestoneWrapper>
          <H as="h3" extraStyles={sectionTitleStyles}>
            {formatMessage({
              id:
                "pages.portfolio.project.timeline.post.quarterlyReport.milestonesTitle.standardPart",
            })}
            <span>
              {formatMessage({
                id:
                  "pages.portfolio.project.timeline.post.quarterlyReport.milestonesTitle.highlightedPart",
              })}
            </span>
          </H>
          <Milestones
            stages={stages}
            reportingEndDate={reportingEndDate}
            reportingStartDate={reportingStartDate}
          />
        </StyledMilestoneWrapper>
      )}

      <StyledParagraphWrapper>
        <H as="h3" extraStyles={sectionTitleStyles}>
          {formatMessage({
            id:
              "pages.portfolio.project.timeline.post.quarterlyReport.projectUpdateTitle",
          })}
        </H>
        {domPurify && !!domPurify.sanitize && projectUpdate && (
          <StyledTextContainer
            dangerouslySetInnerHTML={{
              __html: domPurify.sanitize(projectUpdate),
            }}
          />
        )}
      </StyledParagraphWrapper>

      {seeVideoUpdates.length > 0 && (
        <StyledMediaWrapper>
          <H as="h3" extraStyles={sectionTitleStyles}>
            {formatMessage({
              id:
                "pages.portfolio.project.timeline.post.quarterlyReport.videoUpdatesTitle",
            })}
          </H>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={
              size.width >= theme.breakpoints.medium ? 42 : 50
            }
            totalSlides={seeVideoUpdates.length}
            touchEnabled={false}
          >
            <StyledCarouselWrapper>
              <Slider>
                {seeVideoUpdates.map((video, index) => (
                  <Slide index={index} key={video.videoUrl}>
                    <StyledReactPlayer
                      key={video.videoUrl}
                      url={getMediaUrl(video.videoUrl)}
                      light={getReactPlayerPlaceholder(video.videoThumbnail)}
                      width="100%"
                      height="100%"
                      controls
                      playing={playVideo || currentVideoSlide === index}
                      playIcon={
                        <StyledPlayButton
                          onClick={() => {
                            trackCustomEventTrigger({
                              category:
                                trackingCategoriesHelper.PROJECT_TIMELINE,
                              action: "Click",
                              label: `${trackingCategoriesHelper.PROJECT_TIMELINE} quarterly report button(play)`,
                            })
                          }}
                        >
                          <StyledTriangle />
                        </StyledPlayButton>
                      }
                    />
                  </Slide>
                ))}
              </Slider>
              {size.width < theme.breakpoints.medium &&
                seeVideoUpdates.length > 1 && (
                  <>
                    <StyledSliderButtonBack
                      onClick={() => {
                        setCurrentVideoSlide(currentVideoSlide - 1)
                        setPlayVideo(false)
                        trackCustomEventTrigger({
                          category: trackingCategoriesHelper.PROJECT_TIMELINE,
                          action: "Click",
                          label: `${trackingCategoriesHelper.PROJECT_TIMELINE} quarterly report video button(left arrow)`,
                        })
                      }}
                      disabled={currentVideoSlide === 0}
                    >
                      <ChevronLeft />
                    </StyledSliderButtonBack>
                    <StyledSliderButtonNext
                      onClick={() => {
                        setCurrentVideoSlide(currentVideoSlide + 1)
                        setPlayVideo(false)
                        trackCustomEventTrigger({
                          category: trackingCategoriesHelper.PROJECT_TIMELINE,
                          action: "Click",
                          label: `${trackingCategoriesHelper.PROJECT_TIMELINE} quarterly report video button(right arrow)`,
                        })
                      }}
                      disabled={
                        currentVideoSlide === seeVideoUpdates.length - 1
                      }
                    >
                      <ChevronRight />
                    </StyledSliderButtonNext>
                  </>
                )}
            </StyledCarouselWrapper>
            {size.width >= theme.breakpoints.medium &&
              seeVideoUpdates.length > 1 && (
                <StyledDotContainer
                  currentSlide={handleCurrentThumbnails(
                    seeVideoUpdates,
                    currentVideoSlide,
                    visibleThumbnails
                  )}
                >
                  <StyledThumbnailButtonBack
                    onClick={() => {
                      setCurrentVideoSlide(currentVideoSlide - 1)
                      setPlayVideo(false)
                      trackCustomEventTrigger({
                        category: trackingCategoriesHelper.PROJECT_TIMELINE,
                        action: "Click",
                        label: `${trackingCategoriesHelper.PROJECT_TIMELINE} quarterly report video button(left arrow)`,
                      })
                    }}
                    disabled={currentVideoSlide === 0}
                  >
                    <ChevronLeft />
                  </StyledThumbnailButtonBack>
                  <StyledThumbnailsWrapper
                    visibleThumbnails={visibleThumbnails}
                  >
                    {seeVideoUpdates.map((element, index) => (
                      <Dot
                        slide={index}
                        onClick={() => {
                          setCurrentVideoSlide(index)
                          setPlayVideo(false)
                        }}
                        key={element.videoUrl}
                      >
                        {element.videoThumbnail && (
                          <StyledImage src={element.videoThumbnail} alt="" />
                        )}
                      </Dot>
                    ))}
                  </StyledThumbnailsWrapper>
                  <StyledThumbnailButtonNext
                    onClick={() => {
                      setCurrentVideoSlide(currentVideoSlide + 1)
                      setPlayVideo(false)
                      trackCustomEventTrigger({
                        category: trackingCategoriesHelper.PROJECT_TIMELINE,
                        action: "Click",
                        label: `${trackingCategoriesHelper.PROJECT_TIMELINE} quarterly report video button(right arrow)`,
                      })
                    }}
                    disabled={currentVideoSlide === seeVideoUpdates.length - 1}
                  >
                    <ChevronRight />
                  </StyledThumbnailButtonNext>
                </StyledDotContainer>
              )}
          </CarouselProvider>
        </StyledMediaWrapper>
      )}

      <StyledParagraphWrapper>
        <H as="h3" extraStyles={sectionTitleStyles}>
          {formatMessage({
            id:
              "pages.portfolio.project.timeline.post.quarterlyReport.nextStepsTitle",
          })}
        </H>
        <P extraStyles={textStyles}>{nextSteps}</P>
      </StyledParagraphWrapper>

      <RenderNote
        note={signOffNote}
        noteAuthorSignature={signOffNoteAuthorSignature}
        noteAuthor={signOffNoteAuthor}
        noteAuthorTitle={signOffNoteAuthorTitle}
      />
    </StyledSection>
  )
}

QuarterlyReportPost.propTypes = {
  content: PropTypes.shape({
    publicationDate: PropTypes.string,
    publicationDateDisplaySetting: PropTypes.string,
    category: PropTypes.string,
    title: PropTypes.string,
    quarterlyReportPost: PropTypes.shape({
      welcomeImage: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      welcomeOpenNote: PropTypes.string,
      seePhotosUpdate: PropTypes.string,
      welcomeClosingNote: PropTypes.string,
      welcomeClosingNoteAuthorSignature: PropTypes.string,
      welcomeClosingNoteAuthor: PropTypes.string,
      welcomeClosingNoteAuthorTitle: PropTypes.string,
      signOffNote: PropTypes.string,
      signOffNoteAuthor: PropTypes.string,
      signOffNoteAuthorSignature: PropTypes.string,
      signOffNoteAuthorTitle: PropTypes.string,
      seePhotos: PropTypes.arrayOf(
        PropTypes.shape({
          file: PropTypes.string,
          id: PropTypes.string,
          position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
      ),
      projectUpdate: PropTypes.string,
      seeVideoUpdates: PropTypes.arrayOf(
        PropTypes.shape({
          videoUrl: PropTypes.string,
          videoThumbnail: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
        })
      ),
      nextSteps: PropTypes.string,
      reportingQuarter: PropTypes.string,
      stages: PropTypes.arrayOf(
        PropTypes.shape({
          status: PropTypes.string,

          stage: PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
          }),
          milestones: PropTypes.arrayOf(
            PropTypes.shape({
              position: PropTypes.number,
              status: PropTypes.string,
              completionDate: PropTypes.string,
              title: PropTypes.string,
              body: PropTypes.string,
            })
          ),
        })
      ),
      reportingStartDate: PropTypes.string,
      reportingEndDate: PropTypes.string,
    }),
  }).isRequired,
}

RenderNote.propTypes = {
  note: PropTypes.string.isRequired,
  noteAuthorSignature: PropTypes.string.isRequired,
  noteAuthor: PropTypes.string.isRequired,
  noteAuthorTitle: PropTypes.string.isRequired,
}

export default QuarterlyReportPost
