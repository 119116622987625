import styled, { css } from "styled-components"
import { ButtonBack, ButtonNext } from "pure-react-carousel"
import Image from "components/atoms/Image"
import ReactPlayer from "react-player/lazy"

const carouselButtonsStyles = css`
  position: absolute;
  top: calc(50% - 1.2rem);
  transform: translateY(-50%);
  border: none;
  outline: none;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    fill: ${({ theme }) => theme.color.secondary01};
  }

  @media (any-hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.main03};

      > svg {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }

  &:disabled {
    > svg {
      fill: ${({ theme }) => theme.color.secondary04};
    }
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem ${({ theme }) => theme.layout.mobilePadding} 0;

  ${({ theme }) => theme.mq.medium} {
    max-width: 36.8rem;
    margin: 0 auto;
    padding: 3.2rem 0;
  }
`

const StyledDetailsWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: ${({ theme }) => theme.font.size.xs};
    color: ${({ theme }) => theme.color.secondary02};
  }
`

const StyledTitle = styled.div`
  font-size: ${({ theme }) => theme.font.size.md};
  padding: 0.8rem 0 1.6rem;
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`

const StyledParagraphWrapper = styled.div`
  padding: 1.6rem 0;
`

const StyledDotContainer = styled.div`
  width: max-content;
  display: flex;
  margin: 2.4rem auto 0;

  & .carousel__dot {
    width: 6.4rem;
    height: 6.4rem;
    padding: 0;
    border: 0;
    margin-right: 1.6rem;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;

    transition: transform 300ms ease-in;
    ${({ currentSlide }) =>
      currentSlide &&
      css`
        transform: translateX(
          calc(-100% * ${currentSlide} - 1.6rem * ${currentSlide})
        );
      `};

    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: ${({ theme }) => theme.color.white};
      transition: opacity 300ms linear;
      opacity: 0.6;
      top: 0;
      z-index: 1;
    }

    &.carousel__dot--selected {
      &:before {
        display: none;
      }
    }

    &:hover {
      &:before {
        opacity: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      transition: all 300ms linear !important;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  & .carousel__back-button,
  & .carousel__next-button {
    border: 0;
    background: transparent;
    outline: none;

    & > svg {
      fill: ${({ theme }) => theme.color.secondary02};
      width: 2rem;
      height: 2rem;

      &:hover {
        fill: ${({ theme }) => theme.color.secondary01};
      }
    }
  }

  & .carousel__back-button {
    margin-right: 1.6rem;
  }
  & .carousel__next-button {
    margin-left: 1.6rem;
  }
`

const StyledThumbnailsWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;

  ${({ visibleThumbnails }) =>
    visibleThumbnails &&
    css`
      max-width: calc(
        6.4rem * ${visibleThumbnails} + 1.6rem * ${visibleThumbnails}
      );
    `}
`

const StyledDetailsSeparator = styled.div`
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background: ${({ theme }) => theme.color.secondary03};
  margin: 0 0.8rem;
`

const StyledImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 76.8rem;

  ${({ theme }) => theme.mq.medium} {
    max-width: ${({ theme }) => theme.gridWidth};
  }
`

const StyledSliderButtonBack = styled(ButtonBack)`
  ${carouselButtonsStyles};
  left: 0.8rem;
`
const StyledSliderButtonNext = styled(ButtonNext)`
  ${carouselButtonsStyles};
  right: 0.8rem;
`

const StyledCarouselWrapper = styled.div`
  position: relative;
`

const thumbnailBackButtonStyles = css`
  ${({ disabled }) => disabled && "opacity: 0.2"};
`
const StyledThumbnailButtonBack = styled(ButtonBack)`
  ${thumbnailBackButtonStyles};
`
const StyledThumbnailButtonNext = styled(ButtonNext)`
  ${thumbnailBackButtonStyles};
`
const StyledVideoWrapper = styled.div`
  margin: 0 auto;
  max-width: 76.8rem;
  max-height: 40rem;
  position: relative;

  ${({ theme }) => theme.mq.medium} {
    max-width: ${({ theme }) => theme.gridWidth};
  }
`

const StyledReactPlayer = styled(ReactPlayer)`
  &:hover > div > div:last-child {
    border-color: ${({ theme }) => theme.color.senary03};
  }

  &:hover > div > div:last-child div {
    border-left-color: ${({ theme }) => theme.color.senary03};
  }
`
const textStyles = css`
  hyphens: auto;
  text-align: justify;
`

export {
  StyledImage,
  StyledSection,
  StyledDetailsWrapper,
  StyledTitle,
  StyledParagraphWrapper,
  StyledDotContainer,
  StyledThumbnailsWrapper,
  StyledDetailsSeparator,
  StyledImageWrapper,
  StyledSliderButtonBack,
  StyledSliderButtonNext,
  StyledCarouselWrapper,
  StyledThumbnailButtonBack,
  StyledThumbnailButtonNext,
  StyledVideoWrapper,
  StyledReactPlayer,
  textStyles,
}
