import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { navigate, useIntl } from "gatsby-plugin-intl"

import BtnLink from "components/atoms/BtnLink"
import CloseIcon from "components/atoms/CloseIcon"
import Layout from "components/molecules/Layout"
import PortfolioNavigation from "components/molecules/PortfolioNavigation"
import ChevronLeft from "assets/icons/chevron-left.svg"
import { ThemeProvider } from "styled-components"
import paths from "utils/paths"
import useWindowSize from "utils/useWindowSize"

import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import theme from "styles/theme"
import GlobalStyles from "styles/GlobalStyles"
import UpdatePost from "./UpdatePost"
import VideoReportPost from "./VideoReportPost"
import QuarterlyReportPost from "./QuarterlyReportPost"

import {
  closeButtonStyles,
  linkButtonStyles,
  StyledPostNavigation,
  StyledTitle,
  StyledBackButton,
} from "./ProjectTimelinePosts.styles"

const ProjectTimelinePost = ({ componentData, language }) => {
  const size = useWindowSize()
  const { portfolioProjectTimeline } = paths
  const { formatMessage } = useIntl()
  const { slug } = componentData.project
  const timelinePath = `${portfolioProjectTimeline(slug)}`

  const handleKeyDown = (event) => {
    const { key } = event

    if (key === "Escape" || key === "Esc" || key === "x") {
      navigate(timelinePath)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  const renderPosts = (element) => {
    const postsComponents = {
      VIDEO_REPORT: <VideoReportPost content={element} />,
      UPDATE: <UpdatePost content={element} />,
      QUARTERLY_REPORT: <QuarterlyReportPost content={element} />,
    }
    return postsComponents[element.category] || []
  }

  const postsMobileNavigation = () => (
    <StyledPostNavigation>
      <StyledBackButton>
        <BtnLink extraStyles={linkButtonStyles} to={timelinePath}>
          <ChevronLeft />
          Back
        </BtnLink>
      </StyledBackButton>
      <StyledTitle>{componentData.title}</StyledTitle>
    </StyledPostNavigation>
  )

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ModalRoutingContext.Consumer>
        {({ modal }) => (
          <>
            {modal ? (
              <>
                {size.width >= theme.breakpoints.medium ? (
                  <BtnLink
                    extraStyles={closeButtonStyles}
                    to={timelinePath}
                    data-testid="closeTimelinePost"
                  >
                    <CloseIcon />
                  </BtnLink>
                ) : (
                  postsMobileNavigation()
                )}
                {renderPosts(componentData)}
              </>
            ) : (
              <>
                {size.width >= theme.breakpoints.medium ? (
                  <Layout isNavStatic hideFooter language={language}>
                    <PortfolioNavigation
                      projectData={componentData.project}
                      backButtonProps={{
                        pathname: timelinePath,
                        label: formatMessage({
                          id: "pages.portfolio.project.timeline.backButton",
                        }),
                      }}
                    />
                    {renderPosts(componentData)}
                  </Layout>
                ) : (
                  <>
                    {postsMobileNavigation()}
                    {renderPosts(componentData)}
                  </>
                )}
              </>
            )}
          </>
        )}
      </ModalRoutingContext.Consumer>
    </ThemeProvider>
  )
}

ProjectTimelinePost.propTypes = {
  componentData: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired,
}

export default ProjectTimelinePost
