import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import P from "components/atoms/P"
import formatDate from "utils/formatDate"
import getProjectTimelineReportType from "utils/getProjectTimelineReportType"
import useWindowSize from "utils/useWindowSize"
import handleCurrentThumbnails from "utils/carouselThumbnailsHelper"
import theme from "styles/theme"
import getMediaUrl from "utils/getMediaUrl"
import getReactPlayerPlaceholder from "utils/getReactPlayerPlaceholder"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel"

import ChevronLeft from "assets/icons/chevron-left.svg"
import ChevronRight from "assets/icons/chevron-right.svg"
import {
  StyledImage,
  StyledSection,
  StyledDetailsWrapper,
  StyledTitle,
  StyledParagraphWrapper,
  StyledDotContainer,
  StyledThumbnailsWrapper,
  StyledDetailsSeparator,
  StyledImageWrapper,
  StyledSliderButtonBack,
  StyledSliderButtonNext,
  StyledCarouselWrapper,
  StyledThumbnailButtonBack,
  StyledThumbnailButtonNext,
  StyledVideoWrapper,
  StyledReactPlayer,
  textStyles,
} from "./UpdatePost.styles"

import {
  StyledPlayButton,
  StyledTriangle,
} from "../../ProjectTimeline/VideoReportThumbnail/VideoReportThumbnail.styles"

const UpdatePost = ({ content }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const carouselRef = useRef(null)
  const size = useWindowSize()
  const visibleThumbnails = 5

  const setSlide = (idx) => setCurrentSlide(idx)

  const decreaseSlide = () => {
    const { carouselStore } = carouselRef.current
    const { state } = carouselStore
    const targetSlide = state.currentSlide - 1

    carouselStore.setStoreState({
      ...state,
      currentSlide: targetSlide,
    })

    setCurrentSlide(targetSlide)

    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PROJECT_TIMELINE,
      action: "Click",
      label: `${trackingCategoriesHelper.PROJECT_TIMELINE} update button(left arrow)`,
    })
  }
  const increaseSlide = () => {
    const { carouselStore } = carouselRef.current
    const { state } = carouselStore
    const targetSlide = state.currentSlide + 1

    carouselStore.setStoreState({
      ...state,
      currentSlide: targetSlide,
    })

    setCurrentSlide(targetSlide)

    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PROJECT_TIMELINE,
      action: "Click",
      label: `${trackingCategoriesHelper.PROJECT_TIMELINE} update button(right arrow)`,
    })
  }

  const handleKeyDown = (event) => {
    const {
      carouselStore: { state },
    } = carouselRef.current
    const { key } = event

    if (
      (key === "ArrowRight" || key === "d") &&
      state.currentSlide < content.updatePost.images.length - 1
    ) {
      increaseSlide()
    }
    if ((key === "ArrowLeft" || key === "a") && state.currentSlide >= 1) {
      decreaseSlide()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [currentSlide])

  return (
    <>
      {content.updatePost.mediaType === "VIDEO" ? (
        <StyledVideoWrapper>
          <StyledReactPlayer
            url={getMediaUrl(content.updatePost.video.videoUrl)}
            light={getReactPlayerPlaceholder(
              content.updatePost.video.videoThumbnail
            )}
            width="100%"
            height={400}
            controls
            playing
            playIcon={
              <StyledPlayButton>
                <StyledTriangle />
              </StyledPlayButton>
            }
          />
        </StyledVideoWrapper>
      ) : (
        <StyledImageWrapper>
          <CarouselProvider
            ref={carouselRef}
            naturalSlideWidth={100}
            naturalSlideHeight={
              size.width >= theme.breakpoints.medium ? 52 : 50
            }
            totalSlides={content.updatePost.images.length}
            touchEnabled={false}
          >
            <StyledCarouselWrapper>
              <Slider>
                {content.updatePost.images.map((image, index) => (
                  <Slide index={index} key={image.file}>
                    <StyledImage src={image.file} alt={image.alt} />
                  </Slide>
                ))}
              </Slider>
              {size.width < theme.breakpoints.medium &&
                content.updatePost.images.length > 1 && (
                  <>
                    <StyledSliderButtonBack
                      onClick={decreaseSlide}
                      disabled={currentSlide === 0}
                    >
                      <ChevronLeft />
                    </StyledSliderButtonBack>
                    <StyledSliderButtonNext
                      onClick={increaseSlide}
                      disabled={
                        currentSlide === content.updatePost.images.length - 1
                      }
                    >
                      <ChevronRight />
                    </StyledSliderButtonNext>
                  </>
                )}
            </StyledCarouselWrapper>

            {size.width >= theme.breakpoints.medium &&
              content.updatePost.images.length > 1 && (
                <StyledDotContainer
                  currentSlide={handleCurrentThumbnails(
                    content.updatePost.images,
                    currentSlide,
                    visibleThumbnails
                  )}
                >
                  <StyledThumbnailButtonBack
                    onClick={decreaseSlide}
                    disabled={currentSlide === 0}
                  >
                    <ChevronLeft />
                  </StyledThumbnailButtonBack>
                  <StyledThumbnailsWrapper
                    visibleThumbnails={visibleThumbnails}
                  >
                    {content.updatePost.images.map((element, index) => (
                      <Dot
                        slide={index}
                        onClick={() => setSlide(index)}
                        key={element.title}
                      >
                        {element.mediaType === "VIDEO" &&
                          element.videoPoster && (
                            <StyledImage src={element.videoPoster} alt="" />
                          )}
                        {element.mediaType === "IMAGE" && element.file && (
                          <StyledImage src={element.file} alt="" />
                        )}
                      </Dot>
                    ))}
                  </StyledThumbnailsWrapper>
                  <StyledThumbnailButtonNext
                    onClick={increaseSlide}
                    disabled={
                      currentSlide === content.updatePost.images.length - 1
                    }
                  >
                    <ChevronRight />
                  </StyledThumbnailButtonNext>
                </StyledDotContainer>
              )}
          </CarouselProvider>
        </StyledImageWrapper>
      )}

      <StyledSection>
        <StyledDetailsWrapper>
          <span>
            {formatDate(
              content.publicationDate,
              content.publicationDateDisplaySetting
            )}
          </span>
          <StyledDetailsSeparator />
          <span data-testid="reportType">
            {getProjectTimelineReportType(content.category)}
          </span>
        </StyledDetailsWrapper>
        <StyledTitle>{content.title}</StyledTitle>
        <StyledParagraphWrapper>
          <P extraStyles={textStyles}>{content.updatePost.body}</P>
        </StyledParagraphWrapper>
      </StyledSection>
    </>
  )
}

UpdatePost.propTypes = {
  content: PropTypes.shape({
    updatePost: PropTypes.shape({
      body: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.shape()),
      mediaType: PropTypes.oneOf(["VIDEO", "IMAGES"]),
      video: PropTypes.shape({
        videoThumbnail: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        videoUrl: PropTypes.string,
      }),
    }),
    publicationDate: PropTypes.string,
    publicationDateDisplaySetting: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
}

export default UpdatePost
