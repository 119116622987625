// /* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import PropTypes from "prop-types"
import P from "components/atoms/P"
import formatDate from "utils/formatDate"
import getProjectTimelineReportType from "utils/getProjectTimelineReportType"
import getMediaUrl from "utils/getMediaUrl"
import { useIntl } from "gatsby-plugin-intl"
import getReactPlayerPlaceholder from "utils/getReactPlayerPlaceholder"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import {
  StyledSection,
  StyledDetailsWrapper,
  StyledTitle,
  StyledParagraphWrapper,
  StyledTranscriptionWrapper,
  StyledTranscriptionTitle,
  StyledTranscriptionContent,
  transcriptStyles,
  StyledVideoWrapper,
  StyledDetailsSeparator,
  StyledReactPlayer,
  textStyles,
} from "./VideoReportPost.styles"

import {
  StyledPlayButton,
  StyledTriangle,
} from "../../ProjectTimeline/VideoReportThumbnail/VideoReportThumbnail.styles"

const VideoReportPost = ({ content }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <StyledVideoWrapper>
        <StyledReactPlayer
          url={getMediaUrl(content.videoReportPost.videoUrl)}
          light={getReactPlayerPlaceholder(
            content.videoReportPost.videoThumbnail
          )}
          width="100%"
          height={400}
          controls
          playing
          playIcon={
            <StyledPlayButton
              onClick={() => {
                trackCustomEventTrigger({
                  category: trackingCategoriesHelper.PROJECT_TIMELINE,
                  action: "Click",
                  label: `${trackingCategoriesHelper.PROJECT_TIMELINE} video report button(play)`,
                })
              }}
            >
              <StyledTriangle />
            </StyledPlayButton>
          }
        />
      </StyledVideoWrapper>
      <StyledSection>
        <StyledDetailsWrapper>
          <span>
            {formatDate(
              content.publicationDate,
              content.publicationDateDisplaySetting
            )}
          </span>
          <StyledDetailsSeparator />
          <span data-testid="reportType">
            {getProjectTimelineReportType(content.category)}
          </span>
        </StyledDetailsWrapper>
        <StyledTitle>{content.title}</StyledTitle>
        <StyledParagraphWrapper>
          <P extraStyles={textStyles}>{content.videoReportPost.body}</P>
        </StyledParagraphWrapper>
        <StyledTranscriptionWrapper>
          <StyledTranscriptionTitle>
            {formatMessage({
              id:
                "pages.portfolio.project.timeline.post.videoReport.transcription",
            })}
          </StyledTranscriptionTitle>
          <StyledTranscriptionContent>
            <P extraStyles={transcriptStyles}>
              {content.videoReportPost.transcription}
            </P>
          </StyledTranscriptionContent>
        </StyledTranscriptionWrapper>
      </StyledSection>
    </>
  )
}

VideoReportPost.propTypes = {
  content: PropTypes.shape({
    videoReportPost: PropTypes.shape({
      body: PropTypes.string,
      videoUrl: PropTypes.string,
      videoThumbnail: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      transcription: PropTypes.string,
    }),
    publicationDate: PropTypes.string,
    publicationDateDisplaySetting: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
}

export default VideoReportPost
