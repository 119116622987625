import styled, { css } from "styled-components"
import ReactPlayer from "react-player/lazy"

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem ${({ theme }) => theme.layout.mobilePadding} 0;

  ${({ theme }) => theme.mq.medium} {
    max-width: 36.8rem;
    margin: 0 auto;
    padding: 3.2rem 0;
  }
`

const StyledDetailsWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: ${({ theme }) => theme.font.size.xs};
    color: ${({ theme }) => theme.color.secondary02};
  }
`

const StyledTitle = styled.div`
  font-size: ${({ theme }) => theme.font.size.md};
  padding: 0.8rem 0 1.6rem;
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`

const StyledParagraphWrapper = styled.div`
  padding: 1.6rem 0;
`

const StyledTranscriptionWrapper = styled.div`
  padding: 3.2rem 0 0;
  width: 100%;
`

const StyledTranscriptionTitle = styled.span`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.color.secondary02};
`

const StyledTranscriptionContent = styled.div`
  width: 100%;
  max-height: 7.2rem;
  background: ${({ theme }) => theme.color.secondary06};
  overflow: auto;
  padding: 0 1.6rem;
  margin: 0.8rem 0;
`

const textStyles = css`
  hyphens: auto;
  text-align: justify;
`

const transcriptStyles = css`
  font-size: ${({ theme }) => theme.font.size.xs};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary01};
  padding: 0.8rem;
  ${textStyles}
`

const StyledVideoWrapper = styled.div`
  margin: 0 auto;
  max-width: 76.8rem;
  max-height: 40rem;
  position: relative;

  ${({ theme }) => theme.mq.medium} {
    max-width: ${({ theme }) => theme.gridWidth};
  }
`

const StyledDetailsSeparator = styled.div`
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background: ${({ theme }) => theme.color.secondary03};
  margin: 0 0.8rem;
`

const StyledReactPlayer = styled(ReactPlayer)`
  &:hover > div > div:last-child {
    border-color: ${({ theme }) => theme.color.senary03};
  }

  &:hover > div > div:last-child div {
    border-left-color: ${({ theme }) => theme.color.senary03};
  }
`

export {
  StyledSection,
  StyledDetailsWrapper,
  StyledTitle,
  StyledParagraphWrapper,
  StyledTranscriptionWrapper,
  StyledTranscriptionTitle,
  StyledTranscriptionContent,
  transcriptStyles,
  StyledVideoWrapper,
  StyledDetailsSeparator,
  StyledReactPlayer,
  textStyles,
}
