import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import getProjectProgressStageType from "utils/getProjectProgressStageType"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import SideListItem from "components/atoms/SideListItem"
import sortBy from "utils/sortBy"
import P from "components/atoms/P"
import StageMilestones from "components/content/Portfolio/ProjectProgress/StageMilestones"
import ProgressStagesLabel from "components/content/Portfolio/ProjectProgress/ProgressStagesLabel"

import {
  StyledMilestonesWrapper,
  StyledMilestonesStages,
  StyledMilestonesContent,
  stageDescriptionStyles,
  StyledMilestoneListWrapper,
  textStyles,
} from "./Milestones.styles"

const typeMap = {
  A_OPPORTUNITY_VALIDATION: "Prospecting and acquisition",
  B_PROJECT_PLANNING: "Project Development",
  C_CONSTRUCTION: "Construction",
  D_PROMOTION_AND_OPERATION: "Marketing and operation",
  E_EXIT: "Exit",
}

const Milestones = ({ stages, reportingEndDate, reportingStartDate }) => {
  const [sortedStages, setSortedStages] = useState([])
  const [activeStage, setActiveStage] = useState(null)

  const reportStartDate = new Date(reportingStartDate)
  const reportEndDate = new Date(reportingEndDate)

  const isDateRage = (milestoneDate) => {
    const date = new Date(milestoneDate)
    return reportStartDate < date && date < reportEndDate
  }

  useEffect(() => {
    const mapedStages = stages.map((stage) => ({
      ...stage,
      name: stage.stage.name,
    }))
    const sorted = sortBy(mapedStages, ["name"])
    setSortedStages(sorted)

    const active = sorted.find((stage) =>
      stage.milestones.find(
        (milestone) =>
          milestone.status === "COMPLETE" &&
          isDateRage(milestone.completionDate)
      )
    )

    setActiveStage(active || sorted[0])
  }, [stages])

  const handleActiveStage = (progressStage) => setActiveStage(progressStage)

  const renderStage = (progressStage) => {
    if (
      progressStage.stage.name === "E_EXIT" &&
      progressStage.status !== "COMPLETE"
    ) {
      return null
    }

    const getTotalNumberOfMilestones = progressStage.milestones.length
    const getCompletedNumberOfMilestones = progressStage.milestones.filter(
      (milestone) => milestone.status === "COMPLETE"
    ).length
    const getStatus =
      progressStage.milestones && progressStage.milestones[0].stage.status

    return (
      <SideListItem
        isActive={
          activeStage && activeStage.stage.name === progressStage.stage.name
        }
        onClick={() => {
          handleActiveStage(progressStage)
          trackCustomEventTrigger({
            category: trackingCategoriesHelper.PROJECT_TIMELINE,
            action: "Click",
            label: `${trackingCategoriesHelper.PROJECT_TIMELINE} link(${
              typeMap[progressStage.stage.name]
            })`,
          })
        }}
        key={progressStage.stage.name}
      >
        <ProgressStagesLabel
          progressMilestones={{
            completedNumberOfMilestones: getCompletedNumberOfMilestones,
            totalNumberOfMilestones: getTotalNumberOfMilestones,
            status: getStatus,
          }}
        />
        {getProjectProgressStageType(progressStage.stage.name)}
      </SideListItem>
    )
  }

  return (
    activeStage && (
      <StyledMilestonesWrapper>
        <StyledMilestonesStages>
          {sortedStages && sortedStages.map((stage) => renderStage(stage))}
        </StyledMilestonesStages>
        <StyledMilestonesContent>
          <P extraStyles={stageDescriptionStyles}>
            {getProjectProgressStageType(activeStage.stage.name)}
          </P>
          <P extraStyles={textStyles}>{activeStage.stage.description}</P>
          <StyledMilestoneListWrapper>
            {activeStage.milestones.map((milestone) => (
              <>
                <StageMilestones
                  key={milestone.position}
                  milestone={milestone}
                  isQReport
                  highlight={
                    milestone.status === "COMPLETE" &&
                    isDateRage(milestone.completionDate)
                  }
                />
              </>
            ))}
          </StyledMilestoneListWrapper>
        </StyledMilestonesContent>
      </StyledMilestonesWrapper>
    )
  )
}

Milestones.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  reportingStartDate: PropTypes.string.isRequired,
  reportingEndDate: PropTypes.string.isRequired,
}

export default Milestones
